import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import get from 'lodash/get';
import messages from '../../utils/messages';
import { redirect} from '../../utils/urlHelpers';
import './styles.css';

const { REACT_APP_IMT_URL } = process.env;

const addProductType = (user) => {
  for (const type of ['boat', 'engine', 'atv', 'trailer']) {
    if (get(user, `permissions.imt.${type}`) === 'create') {
      return type;
    }
  }
  return null;
};

const AddListing = ({ user }) => {
  const intl = useIntl();
  const addType = addProductType(user);

  if (addType) {
    const handleAdd = () => redirect(`${REACT_APP_IMT_URL}/${addType}/blast`);
    return (
      <button id="addListing" onClick={handleAdd}>{intl.formatMessage(messages.addlisting)}</button>
    );
  }
  return null;
};

AddListing.propTypes = {
  user: PropTypes.object
};

export default AddListing;
