import { useIntl } from 'react-intl';
import messages from '../../../utils/messages';
import './styles.css';
import { PropTypes } from 'prop-types';

const HelpfulTips = ({ lang }) => {
  const intl = useIntl();
  const t = intl.formatMessage;

  return (
    <div className="helpfulTips">
      <div className="topTip"><span>{t(messages.helpfulTipsTop)}</span></div>
      <ul>
        <li><span>{t(messages.helpfulTipLI1)}</span></li>
        <li><span>{t(messages.helpfulTipLI2)}</span></li>
        <li><span>{t(messages.helpfulTipLI3)}</span></li>
      </ul>
    </div>
  );
};

HelpfulTips.propTypes = {
  lang: PropTypes.string
};

export default HelpfulTips;
