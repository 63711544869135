export const adsConfig = {
  'div-gpt-box-1': {
    unitPath: '/252108799/BoatWizard',
    bp: [2,3,4],
    breakpoint: {
      min: 300,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [250, 250]
    ],
    targeting: {
      pos: 'box-1'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309806'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '767284432'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599750'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1199106'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346706'
          }
        }
      ]
    }
  }
};
