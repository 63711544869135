import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import messages from '../../utils/messages';
import { redirect } from '../../utils/urlHelpers';
import get from 'lodash/get';
import './styles.css';

const { REACT_APP_LEADSMART_URL } = process.env;


const SeeMyLeads = ({ user }) => {
  const intl = useIntl();

  const sendToLeadsmart = () => redirect(REACT_APP_LEADSMART_URL);
  if (get(user,'permissions.leadsmart', false)){
    return (
      <button id="seeMyLeads" onClick={sendToLeadsmart}>{intl.formatMessage(messages.seeMyLeads)}</button>
    );
  }
  return null;
};

SeeMyLeads.propTypes = {
  user: PropTypes.object
};

export default SeeMyLeads;
