import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { Ad, AdProvider } from '@dmm/react-common-components';
import { adsConfig } from './resources/adsConfig';
import get from 'lodash/get';
import messages from '../../utils/messages';
import Card from './Card';
import HelpfulTips from './HelpfulTips';
import Team from './Team';
import CRMStats from './CRMStats';
import Gallery from './Gallery';
import ListingStats from './ListingStats';
import TridentReferral from './TridentReferral';
import './styles.css';

const Cards = ({ user }) => {
  const intl = useIntl();
  const t = intl.formatMessage;

  if (!user.id) {
    return null;
  }
  const hasService = (code) => get(user.services, code);
  const isHideTridentFeatures = hasService('hideTridentFeatures');

  return (
    <AdProvider targeting={{ page: 'home' }} adsConfig={adsConfig}>
      <div className="cards">
        {user.permissions?.imt && <Card title={t(messages.listingStats)}><ListingStats/></Card>}
        {hasService('lm') && <Card title={t(messages.crmStats)}><CRMStats/></Card>}
        {user.services?.export && <Card title={t(messages.viewMyGallery)}><Gallery/></Card>}
        <Card title={t(messages.myTeam)}><Team/></Card>
        <Card title={t(messages.helpfulTips)}><HelpfulTips lang={user.lang}/></Card>
        <Card title={t(messages.advertisement)}>
          <div className="ad">
            <Ad {...{ adId: 'div-gpt-box-1', classes: '' }} />
          </div>
        </Card>
        {!isHideTridentFeatures && <Card title={t(messages.financing)} otherClasses="trident-referral-card" >
          <TridentReferral user={user}/>
        </Card>}
      </div>
    </AdProvider>
  );
};

Cards.propTypes = {
  user: PropTypes.object
};

export default Cards;
